<template>
  <div>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="video-modal"
      size="xl">
      <!-- Modal content -->
      <div class="row text-white content text-left">
        <video @ended="handleVideoEnd" v-if="video" style="width: 100%; height: 100%" controls>
          <source :src="getLink(video)" type="video/mp4">
        </video>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'video-modal',
  props: ['video'],

  computed: {
    ...mapGetters({
      'token': 'auth/token'
    }),
  },

  methods: {
    close() {
      this.$bvModal.hide('video-modal');
    },

    getLink(video) {
      if (!video) return null
      // return process.env.BASE_URL+'api/short-term-courses/'
      //   +video.subjectID+'/video/'+video.sort+'?token='+this.token
      return 'https://api.my.ug.edu.ge/api/short-term-courses/'
        +video.subjectID+'/video/'+video.sort+'?token='+this.token
    },

    handleVideoEnd() {
      this.$emit('ended', this.video)
    }
  }
};
</script>
<style scoped>
.content {
  font-size: 1.6rem;
}
</style>
