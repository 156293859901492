<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>

      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
        <sidebar-sm></sidebar-sm>
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
        <!-- CALENDAR -->
        <section class="short-term-section p-5">
          <div class="bg-light b-30 p-5 shadow-1 row">
            <div class="col-12">
              <p class="instruction"> ქვიზზე გადასასვლელად უყურეთ ყველა ვიდეო მასალას: </p>
            </div>
            <div class="col-12">
              <div
                @click="handleVideoClick(video)"
                :class="['video-item d-flex justify-content-between', {  'cursor-pointer': video.can_see }]"
                v-for="video of videos">
                <div class="title">{{ video.sort }}. {{ video.title }}</div>

                <div v-if="video.seen" class="seen">
                  <img src="/static/images/icons/evaluations/accept.png" /> ნანახია
                </div>
              </div>
              <div
                v-if="hasQuizAccess"
                @click="$router.push('/courses/' + $route.params.subjectId + '/quiz')"
                class="video-item quiz cursor-pointer text-center">
                ქვიზი
              </div>
<!--              <button v-if="seenAll" @click="$router.push('/courses/' + $route.params.subjectId + '/quiz')"-->
<!--                      class="btn btn-red mt-3">-->
<!--                ქვიზი-->
<!--              </button>-->
            </div>
          </div>
        </section>
        <video-modal @ended="handleEnded" :video="chosenVideo" />
      </main>
    </div>
  </div>

</template>

<script>
import breadcrumb from '../components/page/breadcrumb';
import sidebar from '../components/navbar/sidebar';
import navHeader from '../components/navbar/nav-header';
import pageTitle from '../components/page/page-title';
import sidebarSm from '../components/navbar/sidebar-sm';
import PayOnlineModal from '../components/pay-online-modal';
import ShortTermCourseResource from '../api/shortTermCourseResource';
import VideoModal from '../components/short-term/video-modal';

const resource = new ShortTermCourseResource();

export default {
  name: 'course-details',

  components: { VideoModal, PayOnlineModal, breadcrumb, sidebar, navHeader, pageTitle, sidebarSm },

  computed: {
    pageData() {
      return {
        title: this.$t('short-term.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('short-term.title'),
          link: '/',
          isActive: false,
        },{
          icon: '',
          title: this.$t('short-term.course-details'),
          link: '/courses/' + this.$route.params.subjectId,
          isActive: true,
        }],
      };
    }
  },

  data() {
    return {
      videos: [],
      hasQuizAccess: false,
      chosenVideo: null
    }
  },

  mounted() {
    this.loadCourseProgress()
  },

  methods: {
    handleEnded(video) {
      if (this.videos.indexOf(video) === this.videos.length - 1) {
        this.$swal({
          title: "გილოცავთ",
          text: 'თქვენ წარმატებით გაიარეთ კურსი, სერთიფიკატის მისაღებად გთხოვთ დაწეროთ ქვიზი',
          type: "success",
          showCancelButton: true,
          closeOnConfirm: true,
          closeOnCancel: true,
          confirmButtonText: 'ქვიზის გვერდზე გადასხვლა',
          cancelButtonText: 'დახურვა'
        }).then((result) => {
          if(result && result.isConfirmed) {
            this.$router.push('/courses/' + this.$route.params.subjectId + '/quiz')
          }
        });
      }

      resource.seen(video.subjectID, video.ID)
        .then(resp => {
          this.loadCourseProgress()
        })
      this.$bvModal.hide('video-modal')
    },

    handleVideoClick(video) {
      if(!video.can_see) return;

      this.chosenVideo = video
      this.$bvModal.show('video-modal')
    },

    loadCourseProgress() {
      resource.progress(this.$route.params.subjectId)
        .then(resp => {
          this.videos = resp.data.data.videos
          this.hasQuizAccess = resp.data.data.has_quiz_access
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
    }
  }
};
</script>

<style scoped>
.video-item {
  font-size: 18px;
  padding: 14px;
  background: #2d8493;
  color: #fff;
  border-radius: 20px;
  margin-bottom: 16px;
}

.quiz {
  background: #0f6674;
}
.instruction {
  font-size: 16px;
  color: #212529;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 10px;
}
</style>
